<template>
  <div id="campaign">
    <div class="bg-header">
      <img :src="hastag" alt="feel mongolia" class="hastag-img" />
    </div>
    <b-container>
      <b-col class="title-campaign">
        <b-row>
          <b-col md="4">
            <span class="about">About</span>
            <br />
            <span class="hastagfeel">#FeelMongolia</span>
          </b-col>
          <b-col md="8" v-html="campaign.description"></b-col>
        </b-row>
      </b-col>
      <b-col class="list-img">
        <ImagesBoard />
        <!-- <b-row>
          <b-col sm="4" md="3" class="list-box">
            <div class="img-list"></div>
          </b-col>
        </b-row>-->
      </b-col>
    </b-container>
  </div>
</template>

<script>
import axios from "axios";
import hastag from "@/assets/img/campaign/hastagfeel.png";
import ImagesBoard from "@/components/ImagesBoard";
export default {
  name: "campaign",
  data() {
    return {
      hastag: hastag,
      campaign: null
    };
  },
  components: {
    ImagesBoard
  },
  mounted() {
    this.load();
  },
  methods: {
    load() {
      const url = process.env.VUE_APP_API_URI;
      const query = {
        query: `query { campaign(id: "5d5380fe22c5ec0032d9fd93") {
                title name description content socialIdentifiers terms
                prizes { 
                  name description awardType awardOther criteria value images
                }
              }
            }`
      };
      axios
        .post(url, query, {
          headers: {
            "X-API-Key": process.env.VUE_APP_API_KEY
          }
        })
        .then(res => {
          this.campaign = res.data.data.campaign;
        })
        .catch(console.error);
    }
  }
};
</script>

<style lang="scss" scoped>
@media (min-width: 1200px) {
  .container {
    max-width: 1240px;
  }
}

#campaign {
  .bg-header {
    width: 100%;
    height: 100vh;
    background-image: url("../../assets/img/campaign/bg-header.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom;

    .hastag-img {
      position: relative;
      top: 13%;
      left: 50%;
      transform: translate(-50%);
    }
  }

  .title-campaign {
    padding-top: 80px;
    padding-bottom: 70px;

    .about {
      font-weight: bold;
      font-size: 16px;
    }

    .hastagfeel {
      font-weight: bold;
      font-size: 25px;
    }

    @media (min-width: 1024px) {
      .about {
        font-weight: bold;
        font-size: 25px;
      }

      .hastagfeel {
        font-weight: bold;
        font-size: 36px;
      }
    }

    @media (min-width: 1200px) {
      .about {
        font-weight: bold;
        font-size: 25px;
      }

      .hastagfeel {
        font-weight: bold;
        font-size: 48px;
      }
    }
  }

  .list-img {
    margin: -5px 0 30px;
    .list-box {
      padding: 0 5px 10px;
      .img-list {
        width: 100%;
        padding-bottom: 100%;
        background-color: #dddddd;
      }
    }
  }
}
</style>


